import { clsx } from 'clsx'

import abstract_image_0 from "../media/graphics/abstract_0.png"
import abstract_image_1 from "../media/graphics/abstract_1.png"
import abstract_image_2 from "../media/graphics/abstract_2.png"
import abstract_image_3 from "../media/graphics/abstract_3.png"
import abstract_image_4 from "../media/graphics/abstract_4.png"


import React from 'react';

import NoiseSphere from './NoiseSphere';



const images = [
  abstract_image_0,
  abstract_image_1,
  abstract_image_2,
  abstract_image_3,
  abstract_image_4
]


const gradients = [
  ["#80caff", "#FFFFFF"],
  ["#80caff", "#FFFFFF"],
  ["#80caff", "#4f46e5"],
  ["#80caff", "#4f46e5"],
  ["#80caff", "#4f46e5"]
]



const Background = ({
  className,
  demo_is_active,
  sphere_position,
  background_version,
  ...props
}) => {

  let version
  switch (background_version) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 4: {
      version = background_version

      // Always break
      break
    }
    case -1:
    default: {
      const n = 5 // 0 and 1
      version = Math.floor(Math.random() * (n))
      // const version = 0

      // Always break
      break
    }
  }

  return (
    <div className="">

      {/* Abstract */}
      <img
        src={images[version]}
        className={clsx(
          className,
          "absolute inset-0 w-full h-full object-cover"
        )}
      />

      {/* Moving pattern */}
      <div
        className="absolute left-[calc(50%-4rem)] top-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)] animate-float-40s-infinite"
        aria-hidden="true"
      >
        <div
          className={clsx(
            "aspect-[1958/632] lg:aspect-[1108/632] w-[69.25rem] animate-fade-16s-infinite",
            `bg-gradient-to-r from-[${gradients[version][0]}] to-[${gradients[version][1]}]`,
            // "bg-gradient-to-r from-blue-to-purple"
          )}
          style={{
            clipPath:
              'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
          }}
        />
      </div>

      {/* Mesh */}
      <div 
        // className="w-full h-full flex justify-center items-center"
        className="w-full h-full flex justify-center items-center"
      >
        <div 
          // className="h-[360px] w-[360px] absolute top-24 sm:top-44 sm:right-16 sm:h-[480px] sm:w-[480px]"
          className={clsx(
            sphere_position === "hero_index" ? (
              // Hero - Index
              "h-[360px] w-[360px] absolute top-24 sm:top-44 sm:right-16 sm:h-[480px] sm:w-[480px]"
            ) : sphere_position === "hero_about" ? (
              // Hero - About
              "h-[360px] w-[360px] absolute top-24 sm:top-44 sm:h-[480px] sm:w-[480px]"
            ) : sphere_position === "footer" ? (
              // Footer
              "h-[400px] w-[400px] absolute top-0 sm:h-[480px] sm:w-[480px]"
            ) : (
              // Default
              "h-[400px] w-[400px] absolute top-0 sm:h-[480px] sm:w-[480px]"
            )
          )}
        >
          <NoiseSphere version={version} demo_is_active={demo_is_active}  />
        </div>
      </div>
      

    </div>
  )
}



export default Background;

