import Container from "./Container";
import { Heading, Subheading } from "./text";

import abstract_image_0 from "../media/graphics/abstract_0.png"
import abstract_image_1 from "../media/graphics/abstract_1.png"
import abstract_image_2 from "../media/graphics/abstract_2.png"
import abstract_image_3 from "../media/graphics/abstract_3.png"
import abstract_image_4 from "../media/graphics/abstract_4.png"




const stats = [
  { id: 1, title: 'Advanced memory capability', description: 'Coming soon, Aldermate voice companions will be equipped with advanced memory capabilities that allows them to remember and revisit past conversations.',  bg: abstract_image_0 },
  { id: 2, title: 'Biography series', description: 'Based on all the conversations and reminiscence therapy sessions with your loved one, Alderate will generate various biography series centered around certain topics.', bg: abstract_image_1 },
  { id: 3, title: 'Texting capability', description: 'Aldermate voice companions will be able to interact with your loved one via text messages.', bg: abstract_image_2 },
  { id: 4, title: 'Video conferencing', description: 'Aldermate voice companions will be able to interact with your loved one via video conferencing tools such as Zoom or Google Meets.', bg: abstract_image_3 },
]


const ComingSoon = () => {

  return (
    <div className="overflow-hidden py-48">
      <Container>
        <div>
          <Subheading>Future features</Subheading>
          <Heading as="h3" className="mt-2">
            Coming soon.
          </Heading>
        </div>
      </Container>
      <div className="sm:px-24 mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-2">
        {/* Stats Content */}
        {stats.map((stat) => (
          <div key={stat.id} className="relative flex flex-col sm:m-4 z-10 bg-opacity-70 rounded-2xl overflow-hidden mx-4 my-2 sm:m-4 px-4 py-4 sm:py-12">
            
            {/* Background Image */}
            <img
              src={stat.bg}
              className="absolute inset-0 w-full h-full object-cover z-0"
              alt="Background"
            />

            {/* Transparent Overlay */}
            <div className="absolute inset-0 bg-white opacity-60 z-[1]" />
            
            {/* Title */}
            <div className="order-first text-xl sm:text-3xl font-medium tracking-tight text-gray-900 pb-2 z-10">
              {stat.title}
            </div>

            {/* Description */}
            <div className="text-sm/6 sm:text-base/6 font-normal text-gray-600 z-10">
              {stat.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}





export default ComingSoon;

