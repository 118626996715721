import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { Bars2Icon } from '@heroicons/react/24/solid'
import { motion } from 'framer-motion'
// import { Link } from './link'
import { Link } from "react-router-dom"
import { ResponsiveLogo } from './logo'
import { PlusGrid, PlusGridItem, PlusGridRow } from './plusGrid'
import clsx from 'clsx'

const links = [
  { to: '/about', label: 'About' },
  // { to: 'https://blog.aldermate.com', label: 'Blog' },
  { to: 'demo', label: 'Demo' },
  { to: '/signup', label: 'Sign up' },
  { to: 'https://app.aldermate.com/login', label: 'Log in' },
]

const DesktopNav = ({ set_demo_modal_is_open }) => {
  return (
    <nav className="relative hidden lg:flex">
      {links.map(({ to, label }) => (
        <PlusGridItem key={to} className="relative flex">
          <Link
            to={to === "demo" ? null : to} 
            className="flex items-center px-4 py-3 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]"
            onClick={to === "demo" ? (() => set_demo_modal_is_open(true)) : null} 
          >
            {label}
          </Link>
        </PlusGridItem>
      ))}
    </nav>
  )
}

const MobileNavButton = () => {
  return (
    <DisclosureButton
      className="flex size-12 items-center justify-center self-center rounded-lg data-[hover]:bg-black/5 lg:hidden"
      aria-label="Open main menu"
    >
      <Bars2Icon className="size-6" />
    </DisclosureButton>
  )
}

const MobileNav = ({ set_demo_modal_is_open }) => {
  return (
    <DisclosurePanel className="lg:hidden z-30 relative">
      <div className="flex flex-col gap-6 py-4">
        {links.map(({ to, label }, linkIndex) => (
          <motion.div
            initial={{ opacity: 0, rotateX: -90 }}
            animate={{ opacity: 1, rotateX: 0 }}
            transition={{
              duration: 0.15,
              ease: 'easeInOut',
              rotateX: { duration: 0.3, delay: linkIndex * 0.1 },
            }}
            key={to}
          >
            <Link 
              to={to === "demo" ? null : to} 
              className="text-base font-medium text-gray-950" 
              onClick={to === "demo" ? (() => set_demo_modal_is_open(true)) : null} 
            >
              {label}
            </Link>
          </motion.div>
        ))}
      </div>
      <div className="absolute left-1/2 w-screen -translate-x-1/2">
        <div className="absolute inset-x-0 top-0 border-t border-black/5" />
        <div className="absolute inset-x-0 top-2 border-t border-black/5" />
      </div>
    </DisclosurePanel>
  )
}

const Navbar = ({ banner, set_demo_modal_is_open }: { banner?: React.ReactNode, set_demo_modal_is_open: boolean }) => {
  return (
    <Disclosure 
      as="header" 
      className="absolute inset-x-0 top-12 left-6 right-6 md:top-16 md:left-40 md:right-40 z-20"
    >
      {({ open }) => (
        <>

          {/* Backdrop blur with pointer-events-none */}
          {open && (
            <div className="fixed inset-0 fixed-backdrop z-10 pointer-events-auto" />
          )}
          
          {/* Navbar */}
          <PlusGrid className="relative z-20" >
            <PlusGridRow className="relative flex justify-between">
              <div className="relative flex gap-6">
                <PlusGridItem className="py-3">
                  <Link to="/" title="Home">
                    <ResponsiveLogo className="h-9" />
                  </Link>
                </PlusGridItem>
                {banner && (
                  <div className="relative hidden items-center py-3 xl:flex">
                    {banner}
                  </div>
                )}
              </div>
              <DesktopNav set_demo_modal_is_open={set_demo_modal_is_open} />
              <MobileNavButton />
            </PlusGridRow>
          </PlusGrid>
          <MobileNav set_demo_modal_is_open={set_demo_modal_is_open} />
        </>
      )}
    </Disclosure>
  )
}

export default Navbar;