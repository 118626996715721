import { clsx } from 'clsx'
import { ResponsiveLogoMarkOnly } from './logo'



import { CheckIcon, XMarkIcon } from '@heroicons/react/16/solid'





function Row({ children }) {
  return (
    <div className="group relative">
      <div className="absolute inset-x-0 top-1/2 h-0.5 bg-gradient-to-r from-white/15 from-[2px] to-[2px] bg-[length:12px_100%]" />
      <div className="absolute inset-x-0 bottom-0 h-0.5 bg-gradient-to-r from-white/5 from-[2px] to-[2px] bg-[length:12px_100%] group-last:hidden" />
      {children}
    </div>
  )
}

function Logo({ label, className, is_allowed }) {
  return (
    <div
      className={clsx(
        className,
        'absolute top-2 grid grid-cols-[1rem,1fr] items-center gap-2 whitespace-nowrap px-3 py-1',
        'rounded-full bg-gradient-to-t from-gray-100 from-50% to-gray-50 ring-1 ring-inset ring-white/10',
        '[--move-x-from:-100%] [--move-x-to:calc(100%+100cqw)] [animation-iteration-count:infinite] [animation-name:move-x] [animation-play-state:paused] [animation-timing-function:linear] group-hover:[animation-play-state:running]',
      )}
    >
      {is_allowed ? (
        <CheckIcon className="size-4 fill-green-500" />
      ) : (
        <XMarkIcon className="size-4 fill-red-500" />
      )}
      
      <span className="text-sm/6 font-normal text-black">{label}</span>
    </div>
  )
}

// export function DEP_LogoTimeline() {
//   return (
//     <div aria-hidden="true" className="relative h-full overflow-hidden">
//       <div className="absolute inset-0 top-8 z-50 flex items-center justify-center">
//         <div
//           className="absolute inset-0 fixed-backdrop"
//           style={{
//             maskImage: `url('data:image/svg+xml,<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="96" height="96" rx="12" fill="black"/></svg>')`,
//             maskPosition: 'center',
//             maskRepeat: 'no-repeat',
//           }}
//         />
//         <div className="relative flex size-24 items-center justify-center rounded-xl bg-gradient-to-t from-white/5 to-white/25 shadow outline outline-offset-[-5px] outline-white/5 ring-1 ring-inset ring-white/10">
//           <ResponsiveLogoMarkOnly className="h-9 fill-black" />
//         </div>
//       </div>
//       <div className="absolute inset-0 grid grid-cols-1 pt-8 [container-type:inline-size]">
//         <Row>
//           {/* <Logo
//             label="Loom"
//             Logo={LoomLogo}
//             className="[animation-delay:-26s] [animation-duration:30s]"
//           /> */}
//           <Logo
//             label="Gmail"
//             Logo={GmailLogo}
//             className="[animation-delay:-8s] [animation-duration:30s]"
//           />
//         </Row>
//         <Row>
//           <Logo
//             label="Asana"
//             Logo={AsanaLogo}
//             className="[animation-delay:-40s] [animation-duration:40s]"
//           />
//           <Logo
//             label="Microsoft Teams"
//             Logo={MicrosoftTeamsLogo}
//             className="[animation-delay:-20s] [animation-duration:40s]"
//           />
//         </Row>
//         <Row>
//           <Logo
//             label="Google Calendar"
//             Logo={GoogleCalendarLogo}
//             className="[animation-delay:-10s] [animation-duration:40s]"
//           />
//           <Logo
//             label="Google Drive"
//             Logo={GoogleDriveLogo}
//             className="[animation-delay:-32s] [animation-duration:40s]"
//           />
//         </Row>
//         <Row>
//           {/* <Logo
//             label="Basecamp"
//             Logo={BasecampLogo}
//             className="[animation-delay:-45s] [animation-duration:45s]"
//           /> */}
//           <Logo
//             label="Discord"
//             Logo={DiscordLogo}
//             className="[animation-delay:-23s] [animation-duration:45s]"
//           />
//         </Row>
//         <Row>
//           {/* <Logo
//             label="Hubspot"
//             Logo={GmailLogo}
//             className="[animation-delay:-55s] [animation-duration:60s]"
//           /> */}
//           <Logo
//             label="Slack"
//             Logo={SlackLogo}
//             className="[animation-delay:-20s] [animation-duration:60s]"
//           />
//         </Row>
//         <Row>
//           {/* <Logo
//             label="Adobe Creative Cloud"
//             Logo={GmailLogo}
//             className="[animation-delay:-9s] [animation-duration:40s]"
//           /> */}
//           <Logo
//             label="Zoom"
//             Logo={ZoomLogo}
//             className="[animation-delay:-28s] [animation-duration:40s]"
//           />
//         </Row>
//       </div>
//     </div>
//   )
// }



export function LogoTimeline() {
  return (
    <div aria-hidden="true" className="relative h-full overflow-hidden">
      <div className="absolute inset-0 top-8 z-50 flex items-center justify-center">
        <div
          className="absolute inset-0 fixed-backdrop"
          style={{
            maskImage: `url('data:image/svg+xml,<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="96" height="96" rx="12" fill="black"/></svg>')`,
            maskPosition: 'center',
            maskRepeat: 'no-repeat',
          }}
        />
        <div className="relative flex size-24 items-center justify-center rounded-xl bg-gradient-to-t from-white/5 to-white/25 shadow outline outline-offset-[-5px] outline-white/5 ring-1 ring-inset ring-white/10">
          <ResponsiveLogoMarkOnly className="h-9 fill-black" />
        </div>
      </div>
      <div className="absolute inset-0 grid grid-cols-1 [container-type:inline-size]">
        <Row>
          {/* <Logo
            label="Loom"
            Logo={LoomLogo}
            className="[animation-delay:-26s] [animation-duration:30s]"
          /> */}
          <Logo
            label="Jazz music"
            is_allowed={true}
            className="[animation-delay:-8s] [animation-duration:30s]"
          />
        </Row>
        <Row>
          <Logo
            label="Marcus"
            is_allowed={false}
            className="[animation-delay:-40s] [animation-duration:40s]"
          />
          <Logo
            label="Talking about health"
            is_allowed={false}
            className="[animation-delay:-20s] [animation-duration:40s]"
          />
        </Row>
        <Row>
          <Logo
            label="Current events"
            is_allowed={true}
            className="[animation-delay:-10s] [animation-duration:40s]"
          />
          <Logo
            label="Trip to Mexico"
            is_allowed={true}
            className="[animation-delay:-32s] [animation-duration:40s]"
          />
        </Row>
        <Row>
          {/* <Logo
            label="Basecamp"
            Logo={BasecampLogo}
            className="[animation-delay:-45s] [animation-duration:45s]"
          /> */}
          <Logo
            label="Anything finance related"
            is_allowed={false}
            className="[animation-delay:-23s] [animation-duration:45s]"
          />
        </Row>
        <Row>
          {/* <Logo
            label="Hubspot"
            Logo={GmailLogo}
            className="[animation-delay:-55s] [animation-duration:60s]"
          /> */}
          <Logo
            label="Charlie & Joyce"
            is_allowed={true}
            className="[animation-delay:-20s] [animation-duration:60s]"
          />
        </Row>
        <Row>
          <Logo
            label="Knitting"
            is_allowed={true}
            className="[animation-delay:-9s] [animation-duration:40s]"
          />
          <Logo
            label="Estate planning"
            is_allowed={false}
            className="[animation-delay:-28s] [animation-duration:40s]"
          />
        </Row>
      </div>
    </div>
  )
}
