
// React basic
import { useEffect, useState } from 'react'
import { useNavigate, Link, useSearchParams } from "react-router-dom"


// Tailwind
import {
  ChatBubbleLeftRightIcon,
  IdentificationIcon,
  PlayCircleIcon,
  ChartPieIcon,
} from '@heroicons/react/20/solid'
import { ChevronRightIcon } from '@heroicons/react/16/solid'

// Components - utility
import Background from '../components/Background'
import Container from '../components/Container'
import Button from '../components/Button'

// Components - sections
import { Gradient } from '../components/gradient'
import DemoModal from '../components/DemoModal'
import Investors from '../components/Investors'
import Highlights from '../components/Highlights'
import ComingSoon from '../components/ComingSoon'
import Testimonials from '../components/Testimonials'


// Other libraries
import ReactLoading from 'react-loading'


// Custom
import { mixpanel_client_track } from "../libs/mixpanelClient"












const IndexPage = ({
    demo_is_active,
    set_demo_modal_is_open,
  } : {
    demo_is_active : any
    set_demo_modal_is_open : any
  }) => {


  // Navigate
  const navigate = useNavigate()

  // Search params
  const [search_params, _] = useSearchParams()

  // const param_demo_is_active = search_params.get("demo_is_active")

  // States  
  // const [demo_is_active, set_demo_is_active] = useState(false);
  // const [demo_is_active, set_demo_is_active] = useState(demo_is_active_options[0]);
  // const [demo_is_active, set_demo_is_active] = useState(param_demo_is_active === "true")

  // Modal
  // const [demo_modal_is_open, set_demo_modal_is_open] = useState(false)




  useEffect(() => {

    // Mixpanel tracking
    mixpanel_client_track("www_index_visited", null)
    
  }, [])

  return (
    <div id="top" className="">
      
      {/* Hero section */}
      <div className="relative isolate overflow-hidden">
        <div className="absolute inset-2 bottom-0 rounded-4xl ring-1 ring-inset ring-black/5 overflow-hidden bg-gray-100">
          {/* <Gradient className="absolute inset-2 bottom-0 rounded-4xl ring-1 ring-inset ring-black/5" /> */}
          <Background className="" demo_is_active={demo_is_active} sphere_position={"hero_index"} background_version={-1} />
        </div>
        <Container className="relative py-32 md:py-0">
          <div className="pb-24 pt-16 sm:pb-32 sm:pt-24 md:pb-48 md:pt-64">
            <h1 className="font-display text-balance text-6xl/[1.0] font-medium tracking-tight text-gray-950 sm:text-7xl/[1.0] md:text-7xl/[1.0]">
              Voice companion for your loved one.
            </h1>
            <p className="mt-8 max-w-lg text-xl/7 font-medium text-gray-950/75 sm:text-2xl/8">
              AI voice companion that calls your loved one for engaging conversations & reminiscence therapy.
            </p>
            <div className="mt-12 flex flex-col gap-x-6 gap-y-4 sm:flex-row">
              <Button to="/signup" >Sign up</Button>
              <Button variant="secondary" onClick={() => set_demo_modal_is_open(true)}>
                {/* {demo_is_active ? `Call in progress` : `Try calling (332) 334-xxxx`} */}
                {demo_is_active ? (
                  <>
                    <span>
                      {`Call in progress`}
                    </span>
                    <div className="ml-2">
                      <ReactLoading
                        type='spokes'
                        color='#000000'
                        height={20}
                        width={20}
                      />
                    </div>
                  </>
                ) : (
                  <span>
                    {`Demo companion (332) 334-xxxx`}
                  </span>
                )}
              </Button>
            </div>
          </div>
        </Container>
      </div>

      {/* Investors */}
      {/* <Investors /> */}

      {/* Highlights */}
      <Highlights />

      {/* Coming Soon */}
      <ComingSoon />

      {/* Testimonials */}
      {/* <Testimonials /> */}
      
      







      
    </div>
  )
}



export default IndexPage