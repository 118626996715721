import { PauseIcon, PlayIcon } from '@heroicons/react/16/solid';
import { clsx } from 'clsx';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';

const transition = {
  duration: 0.75,
  repeat: Infinity,
  repeatDelay: 1.25,
};


const Circle = ({ size, delay, opacity }) => {
  return (
    <motion.div
      variants={{
        idle: { width: `${size}px`, height: `${size}px` },
        active: {
          width: [`${size}px`, `${size + 10}px`, `${size}px`],
          height: [`${size}px`, `${size + 10}px`, `${size}px`],
          transition: {
            duration: 0.75,
            repeat: Infinity,
            repeatDelay: 1.25,
            ease: 'easeInOut',
            delay,
          },
        },
      }}
      style={{ '--opacity': opacity } as React.CSSProperties}
      className={clsx(
        'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full',
        'bg-[radial-gradient(circle,transparent_25%,color-mix(in_srgb,_theme(colors.blue.500)_var(--opacity),transparent)_100%)]',
        'ring-1 ring-inset ring-blue-500/[8%]',
      )}
    />
  )
}

const Circles = () => {
  return (
    <div className="absolute inset-0">
      <Circle size={528} opacity="3%" delay={0.45} />
      <Circle size={400} opacity="5%" delay={0.3} />
      <Circle size={272} opacity="5%" delay={0.15} />
      <Circle size={144} opacity="10%" delay={0} />
      <div className="absolute inset-0 bg-gradient-to-t from-white to-35%" />
    </div>
  )
}

function Button({ is_playing, onClick }) {
  return (
    <div className="z-50 col-start-1 row-start-1 flex items-center justify-center h-full">
      <motion.div
        variants={{
          idle: { scale: 1 },
          active: {
            scale: [1, 1.05, 1],
            transition: { ...transition, duration: 0.75 },
          },
        }}
        className="flex size-20 items-center justify-center rounded-full bg-gradient-to-t from-blue-500 to-blue-300 shadow cursor-pointer"
        onClick={onClick}
      >
        {is_playing ? (
          <PauseIcon className="size-12 fill-white cursor-pointer" />
        ) : (
          <PlayIcon className="pl-1 size-12 fill-white cursor-pointer" />
        )}
      </motion.div>
    </div>
  );
}

const SampleAudio = () => {
  const [is_playing, set_is_playing] = useState(false);

  const [audio] = useState(
    new Audio(
      'https://aldermate-assets.s3.amazonaws.com/sample-audio-files/joy.wav'
    )
  );

  // Add event listeners to update `is_playing` state
  useEffect(() => {
    const updatePlayingState = () => set_is_playing(!audio.paused);
    const handleAudioEnd = () => set_is_playing(false);

    audio.addEventListener('play', updatePlayingState);
    audio.addEventListener('pause', updatePlayingState);
    audio.addEventListener('ended', handleAudioEnd);

    return () => {
      // Cleanup event listeners
      audio.removeEventListener('play', updatePlayingState);
      audio.removeEventListener('pause', updatePlayingState);
      audio.removeEventListener('ended', handleAudioEnd);
    };
  }, [audio]);

  const handle_click = () => {
    if (is_playing) {
      audio.pause();
    } else {
      audio.play();
    }
  };

  return (
    <div aria-hidden="true" className="relative h-full overflow-hidden">
      <Circles />
      <div className="z-10 absolute left-1/2 h-full w-[26rem] -translate-x-1/2">
        <Button is_playing={is_playing} onClick={handle_click} />
      </div>
    </div>
  );
};

export default SampleAudio;