import { useState, useEffect } from "react"

import ReactLoading from "react-loading"


// Routing
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

// Layouts
import Layout from "./layouts/Layout";

// Routes
import IndexPage from "./pages";
import AboutPage from "./pages/about";
import SignupPage from "./pages/signup";


// Modals
import DemoModal from "./components/DemoModal";

// Other routes
import UnsubscribePage from "./pages/unsubscribe";
import Custom404 from "./pages/404";



// CSS
import "./App.css";

// Client environment
import { browserName, isMobile, isDesktop } from "react-device-detect";
import { scroll_to_anchor_tag_upon_refresh } from "./libs/helpers";






const App = () => {

  // Navigate
  const navigate = useNavigate()

  // Location 
  const location = useLocation();

  // States
  const [demo_modal_is_open, set_demo_modal_is_open] = useState(false)
  const [demo_is_active, set_demo_is_active] = useState(false)

  
  // Token verified
  const [session_is_checked, set_session_is_checked] = useState(false)

  // Awaiting
  const [awaiting, set_awaiting] = useState(true)



  // Upon load
  useEffect(() => {

    // Handle anchor tag upon refresh, if any
    scroll_to_anchor_tag_upon_refresh(location)
    
  }, [location])

  return (
    <>
      {/* Routes */}
      <Routes>
        <Route element={<Layout set_demo_modal_is_open={set_demo_modal_is_open} />}>
          <Route 
            path="/" 
            element={
              <IndexPage 
                demo_is_active={demo_is_active}
                set_demo_modal_is_open={set_demo_modal_is_open} 
              />
            } 
          />
          <Route 
            path="about" 
            element={
              <AboutPage 
                demo_is_active={demo_is_active}
                set_demo_modal_is_open={set_demo_modal_is_open}
              />
            } 
          />
          <Route 
            path="signup" 
            element={
              <SignupPage 
                demo_is_active={demo_is_active}
                set_demo_modal_is_open={set_demo_modal_is_open}
              />
            } 
          />




          {/* <Route 
            path="get-started" 
            element={
              <GetStartedPage 
                demo_is_active={demo_is_active}
                set_demo_modal_is_open={set_demo_modal_is_open} 
              />
            } 
          /> */}


          {/* <Route 
            path="pricing" 
            element={
              <PricingPage 
                set_demo_modal_is_open={set_demo_modal_is_open} 
              />
            } 
          /> */}

          {/* Products */}
          {/* <Route 
            path="products/vansec-simulation" 
            element={
              <ProductsVansecSimulationPage 
                demo_is_active={demo_is_active}
                set_demo_modal_is_open={set_demo_modal_is_open} 
              />
            } 
          /> */}
          {/* <Route 
            path="products/vansec-training" 
            element={
              <ProductsVansecTrainingPage 
                demo_is_active={demo_is_active}
                set_demo_modal_is_open={set_demo_modal_is_open} 
              />
            } 
          /> */}


        </Route>

        <Route path="unsubscribe" element={<UnsubscribePage />} />
        <Route path="*" element={<Custom404 />} />
      </Routes>

      {/* Modals */}
      <DemoModal
        is_open={demo_modal_is_open}
        set_is_open={set_demo_modal_is_open}

        demo_is_active={demo_is_active}
        set_demo_is_active={set_demo_is_active}

        set_refresh_variable={null}
      />

    </>
  );
}

export default App;
