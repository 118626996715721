import { PlusGrid, PlusGridItem, PlusGridRow } from './plusGrid'
import Button from './Button'
import Container from './Container'
import { Gradient } from './gradient'
import { Logo, ResponsiveLogo } from './logo'
import { Subheading } from './text'
import { Link } from 'react-router-dom'
import Background from './Background'

import { ReactComponent as HIPAALogo } from "../media/compliance/hipaa_black.svg"





const CallToAction = () => {
  return (
    <div className="relative pb-16 pt-20 text-center sm:py-24">
      <hgroup>
        <Subheading>Get started</Subheading>
        <p className="mt-6 text-3xl font-medium tracking-tight text-gray-950 sm:text-5xl">
          Voice companion.
          <br/>
          Powered by AI.
        </p>
      </hgroup>
      <p className="mx-auto mt-6 max-w-xs text-sm/6 text-gray-500">
        AI voice companion that calls your loved one for engaging conversations & reminiscence therapy.
      </p>
      <div className="mt-6">
        <Button className="w-full sm:w-auto" to="/signup#top" >
          Sign up
        </Button>
      </div>
    </div>
  )
}

const SitemapHeading = ({ children }) => {
  return <h3 className="text-sm/6 font-medium text-gray-950/50">{children}</h3>
}

const SitemapLinks = ({ children }) => {
  return <ul className="mt-6 space-y-4 text-sm/6">{children}</ul>
}

const SitemapLink = (props) => {
  return (
    <li>
      <Link
        {...props}
        className="font-medium text-gray-950 data-[hover]:text-gray-950/75"
      />
    </li>
  )
}

const Sitemap = ({ set_demo_modal_is_open }) => {
  return (
    <>
      <div>
        <SitemapHeading>Product</SitemapHeading>
        <SitemapLinks>
          <SitemapLink to="" onClick={() => set_demo_modal_is_open(true)}>Demo</SitemapLink>
          <SitemapLink to="/signup#top" >Sign up</SitemapLink>
          <SitemapLink to="https://app.aldermate.com/login" target="_blank" >Log in</SitemapLink>
        </SitemapLinks>
      </div>
      <div>
        <SitemapHeading>Company</SitemapHeading>
        <SitemapLinks>
          <SitemapLink to="/about#top">About</SitemapLink>
          <SitemapLink to="https://blog.aldermate.com">Blog</SitemapLink>
        </SitemapLinks>
      </div>
      <div>
        <SitemapHeading>Support</SitemapHeading>
        <SitemapLinks>
          <SitemapLink to="/about#contact">Contact</SitemapLink>
        </SitemapLinks>
      </div>
      <div>
        <SitemapHeading>Legal</SitemapHeading>
        <SitemapLinks>
          <SitemapLink to="https://app.aldermate.com/terms" target="_blank" >Terms of service</SitemapLink>
          <SitemapLink to="https://app.aldermate.com/privacy" target="_blank" >Privacy policy</SitemapLink>
        </SitemapLinks>
      </div>
    </>
  )
}

const SocialIconX = (props) => {
  return (
    <svg viewBox="0 0 16 16" fill="currentColor" {...props}>
      <path d="M12.6 0h2.454l-5.36 6.778L16 16h-4.937l-3.867-5.594L2.771 16H.316l5.733-7.25L0 0h5.063l3.495 5.114L12.6 0zm-.86 14.376h1.36L4.323 1.539H2.865l8.875 12.837z" />
    </svg>
  )
}

const SocialIconFacebook = (props) => {
  return (
    <svg viewBox="0 0 16 16" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8.05C16 3.603 12.418 0 8 0S0 3.604 0 8.05c0 4.016 2.926 7.346 6.75 7.95v-5.624H4.718V8.05H6.75V6.276c0-2.017 1.194-3.131 3.022-3.131.875 0 1.79.157 1.79.157v1.98h-1.008c-.994 0-1.304.62-1.304 1.257v1.51h2.219l-.355 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.95z"
      />
    </svg>
  )
}

const SocialIconLinkedIn = (props) => {
  return (
    <svg viewBox="0 0 16 16" fill="currentColor" {...props}>
      <path d="M14.82 0H1.18A1.169 1.169 0 000 1.154v13.694A1.168 1.168 0 001.18 16h13.64A1.17 1.17 0 0016 14.845V1.15A1.171 1.171 0 0014.82 0zM4.744 13.64H2.369V5.996h2.375v7.644zm-1.18-8.684a1.377 1.377 0 11.52-.106 1.377 1.377 0 01-.527.103l.007.003zm10.075 8.683h-2.375V9.921c0-.885-.015-2.025-1.234-2.025-1.218 0-1.425.966-1.425 1.968v3.775H6.233V5.997H8.51v1.05h.032c.317-.601 1.09-1.235 2.246-1.235 2.405-.005 2.851 1.578 2.851 3.63v4.197z" />
    </svg>
  )
}

const SocialLinks = () => {
  return (
    <>
      {/* <Link
        to="https://facebook.com"
        target="_blank"
        aria-label="Visit us on Facebook"
        className="text-gray-950 data-[hover]:text-gray-950/75"
      >
        <SocialIconFacebook className="size-4" />
      </Link> */}
      <Link
        to="#"
        target="_blank"
        aria-label="Visit us on X"
        className="text-gray-950 data-[hover]:text-gray-950/75"
      >
        <SocialIconX className="size-4" />
      </Link>
      <Link
        to="#"
        target="_blank"
        aria-label="Visit us on LinkedIn"
        className="text-gray-950 data-[hover]:text-gray-950/75"
      >
        <SocialIconLinkedIn className="size-4" />
      </Link>
    </>
  )
}

const Copyright = () => {
  return (
    <div className="text-sm/6 text-gray-950">
      &copy; {new Date().getFullYear()} PlusIdentity Inc.
    </div>
  )
}


const Footer = ({ set_demo_modal_is_open }) => {
  return (
    <div className="relative isolate overflow-hidden">
      <div className="absolute inset-0 bottom-0 ring-1 ring-inset ring-black/5 overflow-hidden bg-gray-100">
        <Background className="" demo_is_active={false} sphere_position={"footer"} background_version={-1} />
      </div>
      
      {/* Overlay */}
      <div className="absolute inset-2 rounded-4xl bg-white/80" />

      {/* Content */}
      <Container>
        <CallToAction />
        <PlusGrid className="pb-16">





          <PlusGridRow>
            <div className="grid grid-cols-2 gap-y-10 pb-6 lg:grid-cols-6 lg:gap-8">
              <div className="col-span-2 flex">
                <PlusGridItem className="pt-6 lg:pb-6">
                  <ResponsiveLogo className="h-9" />
                </PlusGridItem>
              </div>
              <div className="col-span-2 grid grid-cols-2 gap-x-8 gap-y-12 lg:col-span-4 lg:grid-cols-subgrid lg:pt-6">
                <Sitemap set_demo_modal_is_open={set_demo_modal_is_open} />
              </div>
            </div>
          </PlusGridRow>








          <PlusGridRow className="flex justify-start">

            <div>
              <PlusGridItem className="h-full flex items-center py-3">
                <HIPAALogo className="w-24 h-12" />
              </PlusGridItem>
            </div>


          </PlusGridRow>









          <PlusGridRow className="flex justify-between">


            <div>
              <PlusGridItem className="h-full flex items-center py-3">
                <Copyright />
              </PlusGridItem>
            </div>
            
            <div className="flex">
              <PlusGridItem className="h-full flex items-center gap-8 py-3">
                <SocialLinks />
              </PlusGridItem>
            </div>

            {/* <div>
              <PlusGridItem className="h-full flex items-center py-3">
                <HIPAALogo className="w-24 h-12" />
              </PlusGridItem>
            </div> */}


          </PlusGridRow>




          








        </PlusGrid>
      </Container>
    </div>
  )
}



const DEP_Footer = ({ set_demo_modal_is_open }) => {
  return (
    <footer>
      <Gradient className="relative">
        <div className="absolute inset-2 rounded-4xl bg-white/80" />
        <Container>
          <CallToAction />
          <PlusGrid className="pb-16">
            <PlusGridRow>
              <div className="grid grid-cols-2 gap-y-10 pb-6 lg:grid-cols-6 lg:gap-8">
                <div className="col-span-2 flex">
                  <PlusGridItem className="pt-6 lg:pb-6">
                    <Logo className="h-9" />
                  </PlusGridItem>
                </div>
                <div className="col-span-2 grid grid-cols-2 gap-x-8 gap-y-12 lg:col-span-4 lg:grid-cols-subgrid lg:pt-6">
                  <Sitemap set_demo_modal_is_open={set_demo_modal_is_open} />
                </div>
              </div>
            </PlusGridRow>
            <PlusGridRow className="flex justify-between">
              <div>
                <PlusGridItem className="py-3">
                  <Copyright />
                </PlusGridItem>
              </div>
              <div className="flex">
                <PlusGridItem className="flex items-center gap-8 py-3">
                  <SocialLinks />
                </PlusGridItem>
              </div>
            </PlusGridRow>
          </PlusGrid>
        </Container>
      </Gradient>
    </footer>
  )
}

export default Footer